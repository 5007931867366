<template>
  <section class="myevent">
    <div class="page-header">
      <h3 class="page-title">
        {{$t('myevents')}}
      </h3>      
    </div>

    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else-if="!loading && isEmpty" class="no-item">
      <i class="fas fa-calendar-day text-info"></i>
      <h2 class="mb-2 text-warning mt-4">{{$t('noDataAvailable')}}</h2>
    </div>

    <div v-else class="event-cards row">
        <event-card v-for="event in events" v-bind:event="event" v-bind:key="event.id" v-bind:viewType="viewType" :role="GetCurrentRole()">
          
          <template v-slot:header-right>
            <h6 :class="['small','text-uppercase','mb-0','status-' + GetEventStatus(event.eventStatus)]">
              <small class="mr-2 text-more-muted" v-b-tooltip :title="$t('createdOn')" >{{ GetDate(event.createDate) }}</small>
              <span v-b-tooltip :title="getCloseReason(event.closeReason)">{{ GetEventStatus(event.eventStatus, true) }}</span>
            </h6>
          </template>>

          <template v-slot:date>
            
            <div class="d-flex align-items-center">
              <div class="wrapper d-flex align-items-center media-info">
                  <div class="mb-1">
                  <small class="text-more-muted" v-b-tooltip :title="$t('eventDate')">{{ GetFormattedDatetime(event.startDate) }}</small>
                  </div>         
              </div>
              <div class="wrapper ml-auto action-bar">
                  <div v-if="!IsAdminPage()">
              <b-form-checkbox v-model="event.enableBet" name="check-button" size="sm" switch  @change="onEventEnableChange(event)" :disabled="!isEventEnable(event)" v-b-tooltip :title="$t('enableBettingAfterEventStart')" />
            </div>
              </div>
          </div>
          </template>>

          <template v-slot:body>
            <create-game v-bind:games="getMainGame(event)" v-bind:eventId="event.id" v-bind:mode="mode" v-bind:eventStatus="event.eventStatus"></create-game>
          </template>

          <template v-slot:footer>
            <b-button class="btn-sm btn-icon-text text-uppercase" variant="link" block @click="goDetails(event.id)">
              {{$t('goDetails')}}
              <i class="mdi mdi-arrow-down btn-icon-append"></i>
            </b-button>
          </template>
        </event-card>
    </div>
  </section>
</template>

<script>
import eventCard from './share/event-card.vue'
import createGame from './share/create-event-game'

export default {
  name: 'myevent',
  components: { 
    eventCard,
    createGame
  },
  data () {
    return {
      mode: 'list',
      events: [],
      viewType: 'myevent',
      rawEvents: [],
      loading: true,
      isEmpty: false
    }
  },
  computed: {
    remainSeedMoney(){
        return 0;
      }
  },
  created: function () {
    this.init();
    this.$root.$on('reload', () => { this.init(); });

    this.$bobwinHub.$on('myEventsReceived', response => {
      this.events = JSON.parse(response.data);
      let user = JSON.parse(response.userData);
      this.$store.dispatch('setUser', user);
      this.loading = false;
      this.isEmpty = this.events.length == 0;
    });
  },
  methods: {
    init(){
      if(this.$route.name === 'admin/myevent' && !this.IsAdmin ){
        if (this.$route.path !== '/') this.$router.push({ path: '/'});
      } else {
        this.loading = true;
        this.getMyEvent();
      }
    },
    isEventEnable(event){
      return this.GetEventStatus(event.eventStatus) === 'OPEN';
    },
    getMainGame(event){
      let games = [];
      let defaultGame = event.games.filter((game) => game.isDefault == true);
      if(!defaultGame[0]){
        defaultGame[0] = event.games[0];
      }
      games.push(defaultGame[0]);
      return games;
    },
    getMyEvent() {
      var param = {
        UserId: this.UserId,
        IsAdmin: this.IsAdminPage()
      }
      this.CallHub({task: 'GetMyEvents', callback: 'myEventsReceived', data: JSON.stringify(param)});
    },
    goDetails(eventId){
      let path = this.IsAdminPage() ? '/admin/editevent' : '/editevent';
      this.$router.push({path: path + '/' + eventId});
    },
    onEventEnableChange(event){
      let param = {
          Id: event.id,
          EnableBet: event.enableBet,
          UserId: this.UserId
      };
      this.CallHub({task: 'UpdateEvent', callback: 'eventUpdated', data: JSON.stringify(param), broadcast: 'EventUpdated'});
    },
    getCloseReason(code){
      let reason = '';
      switch(code){
        case 2:
          reason = this.$t('noBets');
          break;
        case 3:
          reason = this.$t('reportAccepted');
          break;                    
      }
      return reason;
    }
  },
  watch:{
    $route (){
        this.getMyEvent();
    }
  }
}
</script>

<style scoped>
.vue-quick-edit {
  display: inline-block;
}
</style>